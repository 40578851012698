import React from 'react';

import SocialDevToLogoSvg from '../../content/assets/social-devto.svg';
import SocialMediumLogoSvg from '../../content/assets/social-medium-nopadding.svg';
import SocialTwitterLogoSvg from '../../content/assets/social-twitter.svg';
import SocialLinkedInLogoSvg from '../../content/assets/social-linkedin.svg';

import './SocialPlatformsBanner.css';

export const SocialPlatformsBanner = ({ children }) => (
  <div className="social-platforms-container">
    <a href="https://www.linkedin.com/in/chak-shun-yu-506316112/" target="_blank" rel="noopener noreferrer">
      <SocialLinkedInLogoSvg className="social-platform-logo linkedin" />
    </a>
    <a href="https://keraito.medium.com" target="_blank" rel="noopener noreferrer">
      <SocialMediumLogoSvg className="social-platform-logo" />
    </a>
    {children}
    <a href="https://twitter.com/keraito" target="_blank" rel="noopener noreferrer">
      <SocialTwitterLogoSvg className="social-platform-logo" />
    </a>
    <a href="https://dev.to/keraito" target="_blank" rel="noopener noreferrer">
      <SocialDevToLogoSvg className="social-platform-logo" />
    </a>
  </div>
);
