import React from 'react';
import { Link } from 'gatsby';

import { rhythm } from '../../utils/typography';

import './layout.css';
import { SocialPlatformsBanner } from '../SocialPlatformsBanner';

const centeredStyle = {
  marginLeft: `auto`,
  marginRight: `auto`,
  maxWidth: rhythm(30),
  padding: rhythm(3 / 4),
};

const Layout = ({ title, children }) => {
  return (
    <div
      style={{
        minHeight: '100vh',
      }}
    >
      <header
        id="page-header"
        style={{
          ...centeredStyle,
          marginTop: 20,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <h4
            style={{
              fontWeight: 700,
              fontSize: rhythm(1.3),
              lineHeight: 1.1,
              textRendering: 'optimizeLegibility',
              textTransform: `none`,
              letterSpacing: `normal`,
              fontFamily: `IBN Plex Sans, Montserrat, sans-serif`,
              margin: 0,
            }}
          >
            {title}
          </h4>
        </Link>
        <div className="page-header-socials" style={{ flex: `0 1 25%` }}>
          <SocialPlatformsBanner />
        </div>
      </header>
      <section style={{ ...centeredStyle, maxWidth: rhythm(24) }}>
        <main>{children}</main>
      </section>
    </div>
  );
};

export default Layout;
