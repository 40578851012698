import Typography from 'typography';
import Wordpress2016 from 'typography-theme-wordpress-2016';

Wordpress2016.overrideThemeStyles = () => {
  return {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    'a.gatsby-resp-image-link > .gatsby-resp-image-image': {
      borderRadius: `var(--large-border-radius)`,
    },
    '@media only screen and (min-width: 768px)': {
      'span.gatsby-resp-image-wrapper': {
        maxWidth: 'none !important',
        width: '110%',
        transform: 'translateX(-5%)',
        marginBottom: '9px',
      },
      '#blog-post h1 > a, #blog-post h2 > a, #blog-post h3 > a, #blog-post h4 > a': {
        boxShadow: 'none',
        paddingRight: '10px !important',
        height: '100%',
        display: 'flex !important',
        alignItems: 'center',
      },
    },
    '#blog-post a': {
      color: 'var(--dark-blue)',
    },
    '#blog-post > ol, #blog-post > ul': {
      marginLeft: '1.75rem',
    },
    '.gatsby-highlight code .token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string': {
      background: 'inherit',
    },
    '#blog-post h1 > a, #blog-post h2 > a, #blog-post h3 > a, #blog-post h4 > a': {
      display: 'none',
    },
  };
};

delete Wordpress2016.googleFonts;

const typography = new Typography({
  ...Wordpress2016,
  headerFontFamily: ['Montserrat', 'sans-serif'],
  bodyFontFamily: ['Merriweather', 'Georgia', 'serif'],
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
